<template>
  <div class="font">
    <ayl-berad-nav :nav="nav" />
    <div class="content-box mt20px">
      <div class="content-main">
        <ayl-table-with-query-object :table="table"></ayl-table-with-query-object>
      </div>
    </div>
    <el-dialog title="审核" :visible.sync="auditDialogVisible" width="30%" center>
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        :inline="true"
        :label-position="$config.labelPosition"
        :label-width="$config.labelWidth"
      >
        <el-form-item label="审核结果" style="width: 100%" prop="checkStatus">
          <el-radio v-model="form.checkStatus" :label="1">通过</el-radio>
          <el-radio v-model="form.checkStatus" :label="0">不通过</el-radio>
        </el-form-item>
        <el-form-item label="审核意见" style="width: 100%">
          <el-input v-model="form.checkResult" placeholder="请输入" maxlength="25"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit">确 定</el-button>
        <el-button @click="onClose">取 消</el-button>
      </span>
    </el-dialog>
        <ayl-dialog
      :visible.sync="isShowDialog"
      :viewUrl="viewUrlForDialog"
      v-model="dataForDialog"
      :async="true"
      :showBottomBtn="false"
      :width="widthForDialog"
    ></ayl-dialog>
  </div>
</template>

<script>
import Adudit from "@/mixins/adudit";
export default {
  mixins: [Adudit],
  data() {
    // eslint-disable-next-line no-unused-vars
    const vm = this;
    return {
      nav: [{ name: "应急演练审核" }],

      // 弹窗参数
      viewUrlForDialog: ``,
      isShowDialog: false,
      dataForDialog: null,
      widthForDialog: "50%",

      aduditAPI: [
        this.$api_hw.operationSafetyManagement_emergencyDrillCheck,
        "id", //key
        "drillId", //value
        "table"
      ],

      common_getBidNameAndIdList: [true, 0, null], //标段管理下拉
      districtInfo_getDistinctCareUnit: [true, 1, null], // 养护企业信息下拉
      common_getDistinctManageUnit: [true, 2, null], //获取主管单位下拉
      auditDialogVisible: false,
      table: {
        api: vm.$api_hw.operationSafetyManagement_emergencyDrillBidObject,
        query: {
          queryCondition: {
            bidId: null,
            careUnitName: null,
            bidManageName: null,
            startTime: null,
            endTime: null,
            checkStatus: null
          }
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "cascader_radio",
            title: "标段名称",
            model: "queryCondition.bidId",
            placeholder: "请选择",
            option: []
          },
          {
            type: "select",
            title: "养护企业",
            model: "queryCondition.careUnitName",
            placeholder: "请选择",
            option: []
          },
          {
            type: "select",
            title: "主管单位",
            model: "queryCondition.bidManageName",
            placeholder: "请选择",
            option: []
          },
          {
            type: "dateSection",
            title: "演练日期",
            isOptions: true,
            modelName: ["queryCondition.startTime", "queryCondition.endTime"]
          },
          {
            type: "select",
            title: "状态",
            model: "queryCondition.checkStatus",
            placeholder: "请选择",
            option: this.$enums_hw.checkStatusOption.list2
          }
        ],
        columns: [
          {
            title: "序号",
            width: "50px",
            $index: "index"
          },
          {
            title: "标段名称",
            key: "bidName"
            // width: "120px"
          },
          {
            title: "养护企业",
            showTooltip: true,
            key: "careUnitName"
          },
          {
            title: "主管单位",
            showTooltip: true,
            key: "bidManageUnit"
          },
          {
            title: "演练时间",
            key: "drillTime",
            filter: "str2ymd",
            width: "170px"
          },
          {
            title: "参与人数",
            // width: "140px",
            key: "participantNum"
          },
          {
            title: "演练内容",
            // width: "100px",
            key: "drillContent"
          },
          {
            title: "状态",
            width: "100px",
            key: "checkStatusDes"
          },
          {
            title: "审核意见",
            width: "100px",
            key: "checkResult"
          },
          {
            title: "操作",
            width: "155px",
            render(h, ctx) {
              return h("span", [
                h(
                  "el-button",
                  {
                    style: {
                      color: "#1ABC9C"
                    },
                    props: {
                      type: "text"
                    },
                    on: {
                      click: vm.onInfo.bind(this, ctx.row)
                    }
                  },
                  "查看"
                ),
                h(
                  "el-button",
                  {
                    style: {
                      color:
                        ctx.row.checkStatus / 1 == 2 ||
                        ctx.row.checkStatus / 1 == 3
                          ? ""
                          : "#1ABC9C"
                    },
                    props: {
                      type: "text",
                      disabled:
                        ctx.row.checkStatus / 1 == 2 ||
                        ctx.row.checkStatus / 1 == 3
                          ? true
                          : false
                    },
                    on: {
                      click: vm.onAudit.bind(this, ctx.row)
                    }
                  },
                  "审核"
                )
              ]);
            }
          }
        ]
      }
    };
  },

  methods: {
    //查看
    onInfo(val) {
      console.log(val);
      this.viewUrlForDialog =
        "/operation-safety-management/emergency-management/info-emergency-drill"
      //传参给弹窗
      this.widthForDialog = "50%";
      this.dataForDialog = {
        id: val.drillId
      };
      this.isShowDialog = true; //显示弹窗
    },
    //审核
    onAudit(val) {
      console.log(val);
      this.aduditAPI[2] = val.drillId;
      this.auditDialogVisible = true;
    }
  },
  async mounted() {
    await this.$search(this.table);
  }
};
</script>
<style lang='sass' scoped>
.btn
  width: 64px
  height: 28px
  border: 1px solid $-color-primary-3
  border-radius: 4px
  color: $-color-primary-3
/deep/ .el-button--primary 
  color: #FFFFFF
  background-color: $-color-primary-3
  border-color: $-color-primary-3
/deep/.el-radio__input.is-checked .el-radio__inner
  border-color: $-color-primary-3
  background: $-color-primary-3
/deep/.el-radio__input.is-checked + .el-radio__label 
  color: $-color-primary-3

.detail-main
  margin: 0px 30px
  font-size: 14px
  font-family: Microsoft YaHei
  font-weight: 400
  color: rgba(102,102,102,1)
  line-height: 46px
</style>